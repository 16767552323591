import React from "react";
import { Outlet } from "react-router-dom";

export default function AnimatedWrapper() {
  return (
      <div className="animated fadeIn">
        <Outlet />
      </div>
  )
}
