import './App.scss';
import React from "react";
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import RequireAuth from './components/RequireAuth';
import AnimatedWrapper from './components/AnimatedWrapper';
import Wrapper from './components/Wrapper';

const Page404 = React.lazy(() => import("./views/pages/Page404"));
const Page403 = React.lazy(() => import("./views/pages/Page403"));
const Page500 = React.lazy(() => import("./views/pages/Page500"));
const ServerNotResponding = React.lazy(() => import("./views/pages/ServerNotResponding"));
const Login = React.lazy(() => import("./views/pages/Login"));
const LoginCallback = React.lazy(() => import("./views/pages/LoginCallback"));
const Layout = React.lazy(() => import("./containers/Layout"));
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const Athena = React.lazy(() => import("./views/Athena"));

const ShowsList = React.lazy(() => import("./views/shows/ShowsList"));
const ShowCreate = React.lazy(() => import("./views/shows/ShowCreate"));
const ShowEdit = React.lazy(() => import("./views/shows/ShowEdit"));
const EpisodeCreate = React.lazy(() => import("./views/shows/EpisodeCreate"));
const EpisodeEdit = React.lazy(() => import("./views/shows/EpisodeEdit"));
const BookingCreate = React.lazy(() => import("./views/bookings/BookingCreate"));
const BookingEdit = React.lazy(() => import("./views/bookings/BookingEdit"));

const ArtistsList = React.lazy(() => import("./views/artists/ArtistsList"));
const ArtistCreate = React.lazy(() => import("./views/artists/ArtistCreate"));
const ArtistEdit = React.lazy(() => import("./views/artists/ArtistEdit"));

const AgenciesList = React.lazy(() => import("./views/agencies/AgenciesList"));
const AgencyCreate = React.lazy(() => import("./views/agencies/AgencyCreate"));
const AgencyEdit = React.lazy(() => import("./views/agencies/AgencyEdit"));
const ContactCreate = React.lazy(() => import("./views/agencies/ContactCreate"));
const ContactEdit = React.lazy(() => import("./views/agencies/ContactEdit"));

const ReportsList = React.lazy(() => import("./views/reports/ReportsList"));

const Settings = React.lazy(() => import("./views/settings/Settings"));
const RatesList = React.lazy(() => import("./views/settings/RatesList"));
const RateCreate = React.lazy(() => import("./views/settings/RateCreate"));
const RateEdit = React.lazy(() => import("./views/settings/RateEdit"));
const RatePriceCreate = React.lazy(() => import("./views/settings/RatePriceCreate"));
const RatePriceEdit = React.lazy(() => import("./views/settings/RatePriceEdit"));

const FeesList = React.lazy(() => import("./views/settings/FeesList"));
const FeeCreate = React.lazy(() => import("./views/settings/FeeCreate"));
const FeeEdit = React.lazy(() => import("./views/settings/FeeEdit"));
const FeePriceCreate = React.lazy(() => import("./views/settings/FeePriceCreate"));
const FeePriceEdit = React.lazy(() => import("./views/settings/FeePriceEdit"));

const DataImport = React.lazy(() => import("./views/DataImport"));
const ScaleImages = React.lazy(() => import("./views/ScaleImages"));

function App() {

  return (
      <React.StrictMode>
        <BrowserRouter>
          <React.Suspense fallback={<Skeleton />}>
            <Routes>
              <Route path="/404" element={<Page404 />} />
              <Route path="/403" element={<Page403 />} />
              <Route path="/500" element={<Page500 />} />
              <Route path="/not-responding" element={<ServerNotResponding />} />
              <Route path="/login" element={<Login />} />
              <Route path="/loginCallback" element={<LoginCallback />} />

              <Route path="/" element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }>
                <Route index element={<Dashboard />} />
                <Route path="/athena" element={<Athena />} />

                <Route path="/shows" element={<AnimatedWrapper />}>
                  <Route index element={<ShowsList />} />
                  <Route path="new" element={<ShowCreate />} />
                  <Route path=":showId" element={<Wrapper />}>
                    <Route index element={<ShowEdit />} />
                    <Route path="episodes/new" element={<EpisodeCreate />} />
                    <Route path="episodes/:episodeId" element={<Wrapper />}>
                      <Route index element={<EpisodeEdit />} />
                      <Route path="bookings/new" element={<BookingCreate />} />
                      <Route path="bookings/:bookingId" element={<BookingEdit />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="/artists" element={<AnimatedWrapper />}>
                  <Route index element={<ArtistsList />} />
                  <Route path="new" element={<ArtistCreate />} />
                  <Route path=":artistId" element={<Wrapper />}>
                    <Route index element={<ArtistEdit />} />
                  </Route>
                </Route>

                <Route path="/agencies" element={<AnimatedWrapper />}>
                  <Route index element={<AgenciesList />} />
                  <Route path="new" element={<AgencyCreate />} />
                  <Route path=":agencyId" element={<Wrapper />}>
                    <Route index element={<AgencyEdit />} />
                    <Route path="contacts/new" element={<ContactCreate />} />
                    <Route path="contacts/:contactId" element={<ContactEdit />} />
                  </Route>
                </Route>

                <Route path="/reports" element={<ReportsList />} />

                <Route path="/settings" element={<AnimatedWrapper />}>
                  <Route index element={<Settings />} />

                  <Route path="rates" element={<Wrapper />}>
                    <Route index element={<RatesList />} />
                    <Route path="new" element={<RateCreate />} />
                    <Route path=":rateId" element={<Wrapper />}>
                      <Route index element={<RateEdit />} />
                      <Route path="prices/new" element={<RatePriceCreate />} />
                      <Route path="prices/:priceId" element={<RatePriceEdit />} />
                    </Route>
                  </Route>
                  <Route path="fees" element={<Wrapper />}>
                    <Route index element={<FeesList />} />
                    <Route path="new" element={<FeeCreate />} />
                    <Route path=":feeId" element={<Wrapper />}>
                      <Route index element={<FeeEdit />} />
                      <Route path="prices/new" element={<FeePriceCreate />} />
                      <Route path="prices/:priceId" element={<FeePriceEdit />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="/import" element={<DataImport />} />
                <Route path="/scaleImages" element={<ScaleImages />} />
              </Route>

              <Route path="*" element={<Page404 />} />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </React.StrictMode>
  );
}

export default App;
