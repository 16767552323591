import React, {useState} from "react";
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import {loginRequest} from "../authConfig";
import {REACT_APP_API_URL} from "../constants";
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { GraphQLError } from 'graphql';
import Observable from 'zen-observable';
import { NotificationManager } from "react-notifications";
import {ssoAuthService} from "../services/ssoAuthService";

export default function ApolloWrapper({children}) {
  const isAuthenticated = useIsAuthenticated();
  const { instance: msalInstance, accounts } = useMsal();
  const [acquiringToken, setAcquiringToken] = useState(false);

  const account = useAccount(accounts[0]);

  // loginDataObject.account.homeAccountId
  // const myAccount  = msalInstance.getAccountByHomeId(homeAccountId);

  const getToken = () => {
    return ssoAuthService.getAccessToken() ? `Bearer ${ssoAuthService.getAccessToken()}` : "";
  };

  const getNewToken = async () => {
    setAcquiringToken(true);
    return msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    }).then((response) => {
      ssoAuthService.login(response);
      setAcquiringToken(false);
    }).catch((error) => {
      setAcquiringToken(false);
      console.error('Error acquiring token:', error);
      return msalInstance.loginRedirect(loginRequest);
    });
  }

  const handleUnauthorized = (operation, forward) => {
    return new Observable(
        (observer) => {
          (async () => {
            try {
              const accessToken = await getNewToken();

              if (!accessToken) {
                throw new GraphQLError('Empty AccessToken');
              }

              // Retry the failed request
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              forward(operation).subscribe(subscriber);
            } catch (err) {
              observer.error(err);
            }
          })();
        }
    );
  }

  const authLink = setContext((request, previousContext) => ({
    headers: { authorization: getToken() }
  }));

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (graphQLErrors.map(e => e.message).some(m => m === "Forbidden")) {
        window.location.href = `${process.env.PUBLIC_URL}/403`;
      }
      if (graphQLErrors.map(e => e.message).some(m => m === "Unauthorized")) {
        if (isAuthenticated) {
          return handleUnauthorized(operation, forward);
        } else {
          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
      );
    }
    if (networkError) {
      console.log("networkError.statusCode", networkError.statusCode);
      if (!networkError.statusCode) {
        window.location.href = `${process.env.PUBLIC_URL}/not-responding`;
      } else if (networkError.statusCode === 500) {
        window.location.href = `${process.env.PUBLIC_URL}/500`;
      } else if (networkError.statusCode === 401) {
        if (isAuthenticated) {
          if (!acquiringToken) {
            console.log("Acquire new token");
            return handleUnauthorized(operation, forward);
          } else {
            console.log("Login not finished, just do nothing");
            // window.location.reload();
          }
        } else {
          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      } else {
        NotificationManager.error("Server error. Please contact system administrator.");
      }
    }
  });

  const httpLink = new HttpLink({
    uri: `${REACT_APP_API_URL}/graphql`
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
  });

  return (
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
  );
}