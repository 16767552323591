const SSO_ACCESS_TOKEN = "sso-access-token";
const SSO_ROLES = "sso-roles";
const SSO_FROM_PAGE = "sso-from-page";
const DEFAULT_PATH = "/";

export const ssoAuthService = {
  storeFromLocation,
  extractFromLocation,
  login,
  logout,
  getAccessToken,
  containsRole
};

function storeFromLocation(location) {
  localStorage.setItem(SSO_FROM_PAGE, location.pathname);
}

function extractFromLocation() {
  const fromLocation = localStorage.getItem(SSO_FROM_PAGE);
  localStorage.setItem(SSO_FROM_PAGE, DEFAULT_PATH);
  return fromLocation || DEFAULT_PATH;
}

function login(data) {
  console.log("Login data", data);
  localStorage.setItem(SSO_ACCESS_TOKEN, data.idToken);
  localStorage.setItem(SSO_ROLES, JSON.stringify(data.idTokenClaims.roles));
}

function logout() {
  localStorage.setItem(SSO_ACCESS_TOKEN, null);
  localStorage.setItem(SSO_ROLES, JSON.stringify([]));
}

function getAccessToken() {
  return localStorage.getItem(SSO_ACCESS_TOKEN);
}

function containsRole(role) {
  if (!localStorage.getItem(SSO_ROLES)) {
    return false;
  }
  return JSON.parse(localStorage.getItem(SSO_ROLES)).includes(role);
}
