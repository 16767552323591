import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import { ssoAuthService } from "../services/ssoAuthService";
import { useIsAuthenticated } from "@azure/msal-react";

export default function RequireAuth({children}) {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (!isAuthenticated) {
    ssoAuthService.storeFromLocation(location);
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
}