export const REACT_APP_API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.REACT_APP_API_URL;
export const PAGE_SIZE = 10;
export const DEFAULT_SHOW_COLOR = '#cccccc';
export const DISCLOSURES = [
  { value: "BASIC", label: "Basic"},
  { value: "ENHANCED", label: "Enhanced"}
];
export const HERITAGES = [
  { value: "Central Asian", label: "Central Asian"},
  { value: "East African", label: "East African"},
  { value: "East Asian", label: "East Asian"},
  { value: "East Caribbean", label: "East Caribbean"},
  { value: "Latino", label: "Latino"},
  { value: "North African", label: "North African"},
  { value: "North Caribbean", label: "North Caribbean"},
  { value: "South African", label: "South African"},
  { value: "South Asian", label: "South Asian"},
  { value: "South Caribbean", label: "South Caribbean"},
  { value: "South East Asian", label: "South East Asian"},
  { value: "Southern European", label: "Southern European"},
  { value: "West African", label: "West African"},
  { value: "West Asian / Middle East", label: "West Asian / Middle East"},
  { value: "West Caribbean", label: "West Caribbean"},
  { value: "White British", label: "White British"},
  { value: "White Other", label: "White Other"}
];
export const DISABILITIES = [
  { value: "Amputee", label: "Amputee"},
  { value: "Cerebral Palsy", label: "Cerebral Palsy"},
  { value: "Deaf / Hearing Impaired", label: "Deaf / Hearing Impaired"},
  { value: "Downs Syndrome", label: "Downs Syndrome"},
  { value: "Dwarfism", label: "Dwarfism"},
  { value: "Neurodiverse", label: "Neurodiverse"},
  { value: "Wheelchair User", label: "Wheelchair User"}
];
export const FEE_TYPES = [
  { value: "REQUIREMENT", label: "Requirement"},
  { value: "PRODUCTION", label: "Production"},
  { value: "OTHER", label: "Other"}
];
export const EPISODES_NUMBER = [
  { value: "1", label: "1"},
  { value: "2", label: "2"},
  { value: "3", label: "3+"}
];
export const NAVIGATION = {
  items: [
    { name: "Shows",    url: "/shows",    roles: ["shows_admin", "bookings_admin"] },
    { name: "Artists",  url: "/artists",  roles: ["artists_admin"] },
    { name: "Agencies", url: "/agencies", roles: ["agents_admin"] },
    { name: "Reports",  url: "/reports",  roles: ["reports_access"] },
    { name: "Settings", url: "/settings", roles: ["settings_admin"] }
  ]
};